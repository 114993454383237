<template>
    <div class="examAdminList">
        <div v-for="(item,index) in list" :key="index" class="item">
            <div>{{item.event_name}}</div>
            <div style="font-size:24px">{{item.name}}</div>
            <div>
                <!-- <el-button @click="toView(item,'/examscreen')">监考屏幕</el-button> -->
                <el-button @click="toView(item,'/exammobile')">监考</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {getAllExams} from '../../api/index'
export default {
    data(){
        return{
            list:[]
        }
    },
    mounted(){
        this.getAllExams()
    },
    methods:{
        getAllExams(){
            getAllExams().then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.list = res.data.data.event_rooms
                }
            })
        },
        toView(item,router){
            let url = this.$router.resolve({
                path:router,
                query:{
                    roomId:item.event_meeting_room_ids[0],
                    event:item.event_name
                }
            })
            window.open(url.href,'_blank')
        }
    }
}
</script>

<style lang="less">
.examAdminList{
    color: #072D76;
    .item{
        width: 90%;
        padding: 20px;
        margin-top:20px;
        border: 2px solid #072D76;
        font-weight: bold;
    }
}
</style>